import "select2"

function appendOption($select, option) {
  $select.append(
    $("<option></option>").attr("value", option.id).text(option.text)
  )
}

function initSelects() {
  // Custom variant attribute select that allows creating new attribute values.
  const $variantAttrsSelect = $(".attribute-select-or-create select")
  $variantAttrsSelect.select2({
    tags: true,
    width: "100%",
  })
  $variantAttrsSelect.addClass("select2-enabled")

  $(
    "select:not(.browser-default):not(.enable-ajax-select2):not([multiple])"
  ).formSelect()
  $("select[multiple]:not(.browser-default):not(.enable-ajax-select2)").select2(
    { width: "100%" }
  )

  $("select.enable-ajax-select2:not(.select2-enabled)").each((i, select) => {
    const $select = $(select)
    const initial = $select.data("initial")
    let insideModal = false
    if ($select.parents("#modal-content").length >= 1) {
      insideModal = true
    }

    if (initial) {
      const initialData = initial instanceof Array ? initial : [initial]
      const selected = initialData.map((item) => {
        appendOption($select, item)
        return item.id
      })
      $select.val(selected)
    }

    $select.select2({
      ajax: {
        url: $select.data("url"),
        delay: 250,
      },
      dropdownParent: insideModal ? $("#modal-content") : null,
      width: "100%",
      minimumInputLength: $select.data("min-input"),
    })
    $select.addClass("select2-enabled")
  })

  $variantAttrsSelect
    .parent()
    .addClass("select2-hidden-accessible select2-enabled")

  $(document).on("select2:open", (e) => {
    e.stopPropagation()
    const nextSibling = $(e.target.nextSibling)
    const select2Field = nextSibling.children().find(".select2-search__field")
    if (select2Field.length >= 1) {
      select2Field.focus()
    } else {
      document.querySelector(".select2-search__field").focus()
    }
  })
}

export { initSelects }
