const onSyncFormSubmit = (e) => {
  const $target = $(e.currentTarget)
  const $submitButton = $target.find("button[type=submit][clicked=true]")
  $submitButton.prop("disabled", true)
  $submitButton.replaceWith(
    $("<div class='loaderContainer' ><div class='loader' ></div></div>")
  )
}

const onSyncFormButtonClick = (e) => {
  const $button = $(e.currentTarget)
  const $formSync = $button.parents("form")
  $("button[type=submit]", $formSync).removeAttr("clicked")
  $button.attr("clicked", "true")
}

// -----

$(document)
  .on(
    "click",
    "form:not('.form-async') button[type=submit]",
    onSyncFormButtonClick
  )
  .on("submit", "form:not('.form-async')", onSyncFormSubmit)
