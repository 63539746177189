import SVGInjector from "svg-injector-2"

import { initSelects } from "./selects"
import { onScroll } from "./utils"

const screenSizes = {
  sm: 600,
  md: 992,
  lg: 1200,
}

const svgInjector = new SVGInjector()

// -----

// Inject all SVGs
svgInjector.inject(document.querySelectorAll("svg[data-src]"))

$(function () {
  // Custom select inputs
  initSelects()

  // Materialize modals
  $(".modal").modal()

  // Initialize dropdowns
  $(".dropdown-trigger:not(#btn-search)").dropdown({
    constrainWidth: false,
  })

  // Initialize tabs
  $(".tabs").tabs()

  // Initialize collapsible
  $(".collapsible").collapsible()
})

// Print button
$(".btn-print").click((e) => {
  window.print()
})

/// / Loading message on page load
// $('body').append('<div style="" id="loadingDiv"><div class="loader">Loading...</div></div>');
// $(window).on('load', function () {
//  setTimeout(removeLoader, 500); //wait for page load PLUS half seconds.
// });
// function removeLoader() {
//  $("#loadingDiv").fadeOut(500, function () {
//    $("#loadingDiv").remove();
//  });
// }

// Pop up for warning
const msg = $("#msg").text().toString()
if (["warning", "error"].includes(msg)) {
  $("#displayData").modal("open")
}

// Clickable rows in dashboard tables
$(document).on("mouseup", "tr[data-action-go] > td:not(.ignore-link)", (e) => {
  if (e.which === 1) {
    const $target = $(e.currentTarget)
    // Ignore selecting text
    const selectedText = getSelection().toString()
    if (selectedText === "" || selectedText === $target.data("ignore-text")) {
      let url = $target.parent().data("action-go")
      if ($target.parent().hasClass("add-port") && window.location.port) {
        const newUrl = new URL(url, window.location.origin)
        newUrl.port = window.location.port
        url = newUrl.href
      }
      window.location.href = url
    } else {
      $target.data("ignore-text", selectedText)
    }
  }
})

// Clickable rows in dashboard tables
$(document).on(
  "click contextmenu",
  "tr[data-action-go] > td:not(.ignore-link)",
  (e) => {
    if (e.which === 3) {
      const $target = $(e.currentTarget)
      let url = $target.parent().data("action-go")
      if ($target.parent().hasClass("add-port") && window.location.port) {
        const newUrl = new URL(url, window.location.origin)
        newUrl.port = window.location.port
        url = newUrl.href
      }
      window.open(url, "_blank")
      return false
    }
  }
)

async function webshareApi(data) {
  try {
    await navigator.share(data)
  } catch (err) {
    console.warn("Error occurred while using webshare.", err)
  }
}

$(document).on("click", ".webshare-button", (e) => {
  e.preventDefault()
  let text = ""
  let url = ""
  e.target.childNodes.forEach((elem) => {
    if (elem.className && elem.className === "webshare-button__text") {
      text = elem.value
    } else if (elem.className && elem.className === "webshare-button__url") {
      url = elem.value
    }
  })
  webshareApi({
    title: "Buyceps",
    text,
    url,
  })
})

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.warn("Error occurred while copying to clipboard -", err)
  }
}

$(document).on("click", ".clipboard-copy", (e) => {
  e.preventDefault()
  e.target.childNodes.forEach((elem) => {
    if (elem.tagName && elem.tagName.toLowerCase() === "input") {
      copyToClipboard(elem.value)
    } else if (elem.tagName && elem.tagName.toLowerCase() === "span") {
      elem.textContent = "Copied!"
    }
  })
})

$(document).on("click id_is_importer", (e) => {
  if ($("#id_is_importer").prop("checked") === true) {
    $("#id_importer").parent().parent().hide()
    $("#id_importer_verification_url").parent().show()
    $("#id_importer_website_url").parent().show()
  } else {
    $("#id_importer").parent().parent().show()
    $("#id_importer_verification_url").parent().hide()
    $("#id_importer_website_url").parent().hide()
  }
})

// Publish / unpublish lever in detail views
const selectors = [
  "#mark-as-fulfilled",
  "#product-is-published",
  "#collection-is-published",
  "#brand-is-published",
  "#variant-is-published",
  "#combo-product-is-published",
  "#variation-is-published",
  "#product-is-listed",
  "#collection-is-listed",
  "#brand-is-listed",
  "#variant-is-listed",
  "#combo-product-is-listed",
  "#variation-is-listed",
  "#customer-phone-verified",
  "#customer-email-verified",
  "#select-payment-method",
  "#toggle-billing-as-shipping",
]
selectors.forEach((selector) => {
  $(selector).on("click", (e) => {
    const form = $(e.currentTarget).closest(".toggle-publish-form")
    const input = form.find(".toggle-publish-switch")[0]
    if (e.target === input) {
      const url = form.attr("action")
      fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "X-CSRFToken": $("[name=csrfmiddlewaretoken]").val(),
        },
      })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          window.location.reload()
        })
    }
  })
})

// Styleguide sticky right menu
onScroll(() =>
  $(".styleguide__menu").toggleClass("fixed", $(window).scrollTop() > 100)
)

// Add port to href
$(() => {
  $("a.add-port").each((index, anchor) => {
    if (window.location.port) {
      const newUrl = new URL($(anchor).attr("href"), window.location.origin)
      newUrl.port = window.location.port
      $(anchor).attr("href", newUrl.href) // Set href value
    }
  })
})

// Tooltip
$(() => {
  $(".tooltipped").tooltip()
})

export { screenSizes, svgInjector, webshareApi }
